.App {
    text-align: center;
  }

  @media (max-width: 1399px){
    body{
      font-size: 12px;
    }
  }
  @media (max-width: 1799px) and (min-width: 1400px){
    body{
      font-size: 16px;
    }
  }
  @media (min-width: 1800px){
    body{
      font-size: 20px;
    }
    }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }

  .logo{
    margin-top: 2em;
  }

  input {
    box-sizing: border-box;
    border: none;
    margin: 0.25em;
    border-radius: 1em;
    font-size: 18px;
    padding: 4px;
    padding-left: 1em;
    padding-right: 1em;
    max-width: 100%;
    &:hover {
      background-color:rgb(190, 206, 201);
    }
  }
  input.login{
    font-size: 22px;
    width: 10em;
    padding: 8px;
    margin: 1em;
    margin-top: 0.3em;
  }

  .ad_units{
    p{
      display: inline;
      margin: 1em;
    }
  }
  .pointer{
    cursor: pointer !important
  }

  nav{
    position: fixed;
    background-color: #FFFBEB;
    width: 100vw;
    height: 4em;
    top: 0;
    border-bottom: 4px solid #5773FF;

    img{
      position: absolute;
      left: 2em;
      top: 0.05em;
      height: 4em;
      width: 4em;
    }

    ul li{
      display: inline-flex;
    }
    li a{
      font-size: 1.25em;
      text-decoration: none !important;
      margin-left: 1.5em;
      margin-right: 1.5em;
      font-weight: bold;
      color: rgb(38,84,22) !important;
      &:hover{
        color: rgb(95, 173, 67) !important;
      }
    }
  }

.error{
  margin-top: 5em;
}

  ul.nav{
    li{
      cursor: pointer;
    }
  }

  h1{
    margin-top: 2.5em;
  }

  hr{
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .container{
    width: 100vw;
    max-width:100%;  /* added */
    box-sizing: border-box;
    padding-right: 2em;
    padding-left: 2em;
  }

  // Tables
table{
  width: 100%;
  table-layout: fixed ;
  border-collapse: collapse; 
  tr{
    border-top: 2px solid #5773FF;

    button, input[type=submit]{
      cursor: pointer !important;
      border: none;
      margin: 0.1em;
      border-radius: 1em;
      font-size: 18px;
      padding: 4px;
      padding-left: 1em;
      padding-right: 1em;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      width: 6em;
      &:hover {
        background-color:rgb(190, 206, 201);
      }
    }

    img{
      height: 4em;
      width: 4em;
    }

    td{
      width: 9%;
    }
    th{
      width: 9%;
      background-color: #FFFBEB;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      color: rgb(38,84,22);
    }
  }
}

button.new{
  cursor: pointer !important;
  border: none;
  margin: 0.1em;
  border-radius: 1em;
  font-size: 18px;
  padding: 4px;
  padding-left: 1em;
  padding-right: 1em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  width: 20em;
  &:hover {
    background-color:rgb(190, 206, 201);
  }
}

  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  